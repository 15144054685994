/* @font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'), url(./fonts/Helvetica/Helvetica-01.ttf) format('truetype');
} */

@font-face {
  font-family: "HelveticaNeueBold";
  src: local("HelveticaNeueBold"),
    url("./fonts/HelveticaNeue/HelveticaNeue-Bold-02.ttf") format("truetype");
}

@font-face {
  font-family: "HelveticaNeueCondenseBold";
  src: local("HelveticaNeueCondenseBold"),
    url("./fonts/HelveticaNeue/HelveticaNeue-CondensedBold-05.ttf")
      format("truetype");
}
body {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Helvetica", "Helvetica Neue", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flip-card {
  width: 100%;
  height: 550px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: transparent;
}

/* Style the back side */
.flip-card-back {
  background-color: rgba(0, 188, 228, 0.8);
  color: white;
  transform: rotateY(180deg);
}
